<template>
    <div class="mapping-container">
      <div class="groups">
        <div class="header">
          <labelc text="Groups"/>
          <buttonc type="icon-overlay" :icon="groupsCollapsed ? 'fa-angle-down' : 'fa-angle-up'" @click="groupsCollapsed = !groupsCollapsed"/>
        </div>
        <div class="group-container"  v-if="!groupsCollapsed" v-bind:group="group.label" v-for="(group, gindex) in mappingGroups" :key="gindex + group.label" v-bind:class="{collapsed: group.collapsed}">
          <div class="item-header">
            <labelc :text="group.label"></labelc>
            <!-- <buttonc :icon="group.collapsed ? 'fa-angle-down' : 'fa-angle-up'" @click="group.collapsed = !group.collapsed, RefreshMapLines()"/>-->
          </div>
          <div class="item-container">
            <div class="item-mapped"  v-bind:item="itemMapped.label" v-if="!hideMapped || (hideMapped && !itemMaps.find(i => (i.from.group === group && i.from.item === itemMapped.label) || (i.to.group === group && i.to.item === itemMapped.label)))"  v-bind:id="itemMapped.label" v-for="(itemMapped, mindex) in group.itemsMapped" :key="mindex + itemMapped.label" @mousedown="StartDrag($event, itemMapped, group)">
              <labelc :text="itemMapped.label"/>
            </div>
          </div>
        </div>
      </div>
      <div class="maps">
        <div class="header">
          <labelc text="Mapped Items"/>
          <buttonc type="icon-overlay" :icon="mapsCollapsed ? 'fa-angle-down' : 'fa-angle-up'" @click="mapsCollapsed = !mapsCollapsed"/>
        </div>
        <div class="mapped-container" v-if="!mapsCollapsed" v-bind:item="group.label" v-for="(group, gindex) in MappedDisplay" :key="gindex + group.label" v-bind:class="{collapsed: group.collapsed}">
          <div class="item-header">
            <labelc :text="group.label"></labelc>
          </div>
          <div class="item-container">
            <div class="item-mapped" v-for="(itemMapped, mindex) in group.itemsMapped" :key="mindex + itemMapped.from.item + itemMapped.to.item">

              <labelc :text="itemMapped.from.item"/>
              <div class="arrow-in"></div>
              <div class="arrow-in bg"></div>
              <labelc class="into" :text="itemMapped.to.group.label + ' | ' +itemMapped.to.item"/>
              <div class="arrow-in"></div>
              <buttonc type="no-background round" icon="fa-times" v-on:click="RemoveItemMap(itemMapped)"/>
            </div>
          </div>
        </div>
      </div>
      <buttonc class="save-button" icon="fa-save" label="Speichern" @click="SaveMapping()"/>
      <div class="map-lines">
        <div class="map-line" v-if="itemMap.line" v-for="(itemMap, index) in itemMaps" v-bind:id="'line' + index" :key="index"
            v-bind:style="{left: itemMap.line.x + 'px', top: itemMap.line.y + 'px', height: itemMap.line.height + 'px', width: itemMap.line.width + 'px'}">
          <canvas v-bind:width="itemMap.line.width" v-bind:height="itemMap.line.height"></canvas>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "datamapper",
        data(){
            return{
              mappingGroups: [],
              itemMaps: [],
              collapsedDefault: false,
              hideMapped: true,
              groupsCollapsed: false,
              mapsCollapsed: false,
            }
        },
        computed:{
          MappedDisplay(){
            let map = [];
            let itemsGroupMapped = {};
            if(!this.itemMaps) this.itemMaps = [];
            this.itemMaps.forEach(i => {
              if(!itemsGroupMapped[i.from.group.label]) itemsGroupMapped[i.from.group.label] = [];
              itemsGroupMapped[i.from.group.label].push(i);
            });
            for(let group of this.mappingGroups){
              let groupLabel = group.label;
              let entry = {
                label: groupLabel,
                itemsMapped: itemsGroupMapped[groupLabel] ? itemsGroupMapped[groupLabel] : [],
              };
              map.push(entry);
            }
            return map;
          },
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            },
            dataMaps: {
                immediate: true,
                handler(dataMaps, oldVal) {
                  this.RefreshMappingGroups();
                }
            }
        },
        props:{
            state: Object,
            dataMaps: Object,
        },
        mounted(){
          let groups = {};
            /*Object.entries(groups).forEach(entry => {
              let key = entry[0];
              let value = entry[1];
              value.label = key;
            });*/
            this.RefreshMappingGroups(groups);
            let view = this;
            requestAnimationFrame(function() {
              requestAnimationFrame(function() {
                view.RefreshMapLines();
              });
            });
            this.$el.addEventListener('scroll', this.RefreshMapLines);
            this.$el.parentNode.addEventListener('scroll', this.RefreshMapLines);
        },
        beforeDestroyed(){
            this.$el.removeEventListener('scroll', this.RefreshMapLines);
        },
        methods:{
            RemoveItemMap(itemMap){
              this.itemMaps = this.itemMaps.filter(i => i !== itemMap);
              this.RefreshMapLines();
            },
            RefreshMapLines(){
              let view = this;
              requestAnimationFrame(function(){
                requestAnimationFrame(function(){
                  for(let key in view.itemMaps){
                    let itemMap = view.itemMaps[key];
                    itemMap.line = null;
                    let boxFrom = view.$el.querySelector('[group="' + itemMap.from.group.label + '"] [item="' + itemMap.from.item + '"]');
                    if(view.mappingGroups.find(g => g.label === itemMap.from.group.label && g.collapsed)) boxFrom = view.$el.querySelector('.groups ' + '[group="' + itemMap.from.group.label + '"]')
                    if(boxFrom) boxFrom = boxFrom.getBoundingClientRect();
                    let boxTo = view.$el.querySelector('[group="' + itemMap.to.group.label + '"] [item="' +  itemMap.to.item + '"]');
                    if(view.mappingGroups.find(g => g.label === itemMap.to.group.label && g.collapsed)) boxTo = view.$el.querySelector('.groups ' + '[group="' + itemMap.to.group.label + '"]')
                    if(boxTo) boxTo = boxTo.getBoundingClientRect();
                    if(boxFrom && boxTo) {
                      let lineMargin = 10;
                      itemMap.from.x = boxFrom.right - 0;
                      itemMap.from.y = boxFrom.top + (boxFrom.height * .5);
                      itemMap.to.x = boxTo.left;
                      itemMap.to.y = boxTo.top + (boxTo.height * .5);
                      itemMap.line = {};
                      itemMap.line.x = boxTo.left < boxFrom.right ? itemMap.to.x : itemMap.from.x;
                      itemMap.line.y = boxTo.top < boxFrom.top ? itemMap.to.y : itemMap.from.y;
                      itemMap.line.height = Math.abs(itemMap.from.y - itemMap.to.y);
                      itemMap.line.width = Math.abs(itemMap.from.x - itemMap.to.x);
                      if (boxTo.left < boxFrom.left) {
                        itemMap.line.x -= 40;
                        itemMap.line.width += 80;
                      }
                    }
                  }
                  view.$forceUpdate();
                  if(!view.hideMapped) {
                    requestAnimationFrame(function () {
                      for (let key in view.itemMaps.filter(i => i.line)) {
                        let itemMap = view.itemMaps[key];
                        const canvas = view.$el.querySelector('#line' + (key) + ' canvas');
                        const ctx = canvas.getContext('2d');
                        let start = {
                          x: 0,
                          y: 1
                        };
                        let cp1 = {
                          x: 30,
                          y: 1
                        };
                        let cp2 = {
                          x: itemMap.line.width - 30,
                          y: itemMap.line.height - 1
                        };
                        let end = {
                          x: itemMap.line.width,
                          y: itemMap.line.height - 1
                        };
                        if (itemMap.from.x < itemMap.to.x) {
                          if (itemMap.from.y > itemMap.to.y) {
                            let temp = end.x;
                            end.x = start.x;
                            start.x = temp;
                            temp = cp1.x;
                            cp1.x = cp2.x;
                            cp2.x = temp;
                          }
                        } else {
                          start.x = 80;
                          cp1.x = -250;
                          cp2.x = itemMap.line.width + 250;
                          end.x = itemMap.line.width - 80;
                          cp1.y += 30;
                          cp2.y -= 30;
                          if (itemMap.from.y < itemMap.to.y) {
                            let temp = end.x;
                            end.x = start.x;
                            start.x = temp;
                            temp = cp1.x;
                            cp1.x = cp2.x;
                            cp2.x = temp;
                          }
                        }
                        ctx.beginPath();
                        ctx.moveTo(start.x, start.y);
                        ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, end.x, end.y);
                        ctx.lineWidth = 1;
                        ctx.stroke();
                      }
                      view.$forceUpdate();
                    });
                  }
                });
              });
            },
            StartDrag($event, itemMapped, group){
              let view = this;
              this.$helpers.OpenDragPopup({

                components: [{
                    type: 'labelc',
                    propContainer: {
                        text: itemMapped.label,
                    }
                }],
                    remainAfterSelect: false,
              }, (element, dropdowncontainer) => {

                let group = $(element).closest('.group-container').attr('group');
                let item = $(element).closest('.item-mapped').attr('item');
                if(!group || group === '' || !item || item === '' || group === itemMapped.group.label) {
                  return;
                }
                view.itemMaps.push({
                  from: {group: this.mappingGroups.find(g => g.label === itemMapped.group.label), item: itemMapped.label},
                  to: {group: this.mappingGroups.find(g => g.label === group), item: item},
                });
                view.RefreshMapLines();
                dropdowncontainer.Close();
                //console.log(view.itemMaps[view.itemMaps.length - 1]);
              });
            },
            RefreshMappingGroups(groups){
                if(!groups) groups = this.dataMaps;
                if(!groups) groups = {};
                let view = this;
                this.mappingGroups = [];
                Object.entries(groups).forEach(entry => {
                  let key = entry[0];
                  let value = entry[1];
                  let group = {itemsMapped: [], collapsed: view.collapsedDefault};
                  group.label = key;
                  Object.entries(value).forEach(entryItem => {
                  let keyItem = entryItem[0];
                  let valueItem = entryItem[1];
                    let item = {};
                    item.label = keyItem;
                    item.value = valueItem;
                    item.group = group;
                    group.itemsMapped.push(item);
                  });
                  view.mappingGroups.push(group);
                });
            },
            SaveMapping(){
              let view = this;
              let map = {};
              this.itemMaps.forEach(i => {
                if(!map[i.from.group.label]) map[i.from.group.label] = {};
                let fromValue = view.mappingGroups.find(m => m.label === i.from.group.label).itemsMapped.find(item => item.label === i.from.item).value;
                let toValue = view.mappingGroups.find(m => m.label === i.to.group.label).itemsMapped.find(item => item.label === i.to.item).value;
                map[i.from.group.label][i.from.item] = {group: i.to.group.label, key: i.to.item, toValue: toValue, fromValue: fromValue};
              });
              this.$emit('OnSave', map);
            },
            CheckListenerExists(eventName){
                return this.$listeners[eventName];
            },
            ItemClicked(item){
                if(item.click != undefined){
                    item.click();
                }
                if(item.closeOnClick){
                    this.Close();
                }
            },
            Close(){
                this.$parent.Toggle();
            },
        }
    }
</script>

<style scoped>
  .mapping-container{
    user-select: none;
    white-space: nowrap;
    display: flex;
    position: relative;
  }

  .group-container, .mapped-container{
    margin: 10px;
    /*width: 200px;*/
    padding: 0 0 10px;
    display: inline-block;
    z-index: 1;
    position: relative;
    background: var(--bg);
    margin: 50px;
  }

  .groups, .maps{
    width: calc(50% - 40px);
    margin: 0 20px;
    display: inline-flex;
    justify-content: space-evenly;
    position: relative;
    min-height: 50px;
  }

  .header{
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
  }

  .header, .header .label{
    width: 100%;
  }

  .header .label{
    font-weight: 500;
    border-bottom: 1px solid var(--contrast-3);
  }

  .header .button{
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto !important;
  }

  .maps{
    display: block;
  }

  .group-container{
    margin: 50px 0;
  }


  .mapped-container{
    display: block;
    width: fit-content;
  }

  .group-container.collapsed{

    padding-bottom: 0px;
  }

  .item-header{
    padding: 5px;
    position: relative;
    text-align: left;
    padding-left: 10px;
    padding-right: 30px;
    box-shadow: 0 1px 0 0 var(--contrast-3);
  }

  .item-header .label{

    color: #e51550;
    }

  .item-header .button{
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .item-container{

  }

  .collapsed .item-container{
    display: none;
  }

  .item-mapped{
    cursor: grab;
    position: relative;
    white-space: nowrap;
    padding: 0 10px;
    text-align: left;

  }

  .maps .item-mapped{
    padding-left: 30px;
    }

  .mapped-container .item-mapped{
    cursor: default;
  }
  .mapped-container .item-mapped .button{
    position: absolute;
    top: 7px;
    bottom: 0;
    left: 5px;
    margin: auto;

  }



  .map-lines{

  }

  .map-line{
    position: fixed;
  }

  .map-line .start, .map-line .line, .map-line .end{
    position: fixed;
    pointer-events: none;
    z-index: 0;
  }

  .map-line .start, .map-line .end{
    height: 1px;
    background: var(--contrast-4);
    width: 10px;
  }
  .map-line canvas{
    height: 100%;
    width: 100%;
  }
  .item-mapped .label{
    pointer-events: none;
    transition: .2s !important;
    box-shadow: 0 1px 0 0px var(--contrast-3);
    padding: 2px;
    padding-left: 5px;
  }

  .maps .item-mapped .label{
    box-shadow: 0 0 0 1px var(--contrast-3);
  }

  .item-mapped .label.into{
    padding-left: 15px;
  }

  .arrow-in{
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid var(--contrast-3);
    display: inline-block;
    position: relative;
    margin-top: 7px;
    margin-bottom: -7px;
    margin-left: -5px;
  }
  .arrow-in::after{
    content: '';
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid var(--bg);
    display: inline-block;
    position: absolute;
    right: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .arrow-in.bg{
    margin-left: 0px;
    margin-right: -15px;

  }

  .groups .item-mapped:hover{
    background: #e51550;
  }

  .groups .item-mapped:hover .label{
    color: white;
  }

  .save-button{

    position: absolute;
    right: 5px;
    top: -60px;
  }
  @media (max-width: 1200px) {


      .groups, .maps{
        width: calc(100% - 50px);
      }

      .mapping-container{
        text-align: center;
        justify-items: center;
        flex-direction: column;
      }

  }
</style>
